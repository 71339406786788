.content {
  display: flex;
  flex-direction: column;
}
.wrap{
  text-align: center;
}  
.ref img {
  position: absolute;
  margin-top: -96.5%;
  width: 100%;
  z-index: 1;
  opacity: 0.8;
}
.row{
  flex-direction: row;
	flex-wrap: wrap;
} 
.bbb {
  width: 1920px;
  height: 1080px;
  padding: -500px -400px 0px 0px;
  background-color: #fff;
}
.bgappdownload {
  width: 1791.5px;
  height: 734px;
  padding: -100px -100px 0px 0px;
  object-fit: contain;
}
.denttop001 {
  width: 258px;
  height: 53px;
  margin: 32px 177px 354px 0;
  object-fit: contain;
  font-family: NanumSquare_acR;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}
.denttop002 {
  width: 152px;
  height: 53px;
  margin: 0 218px 32px 177px;
  object-fit: contain;
  font-family: NanumSquare_acR;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}
.denttop003 {
  width: 176px;
  height: 53px;
  margin: 0 183px 32px 218px;
  object-fit: contain;
  font-family: NanumSquare_acR;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}
.denttop004 {
  width: 222px;
  height: 53px;
  margin: 0 0 32px 183px;
  object-fit: contain;
  font-family: NanumSquare_acR;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}
.denttop005 {
  width: 240px;
  height: 53px;
  margin: 354px 186px 0 9px;
  object-fit: contain;
  font-family: NanumSquare_acR;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}


/* DivTable.com */
.divTable{
	display: table;
	width: 100%;
}
.divTableRow {
	display: table-row;
}
.divTableHeading {
	background-color: #EEE;
	display: table-header-group;
}
.divTableCell, .divTableHead {
	border: 1px solid #999999;
	display: table-cell;
	padding: 3px 10px;
}
.divTableHeading {
	background-color: #EEE;
	display: table-header-group;
	font-weight: bold;
}
.divTableFoot {
	background-color: #EEE;
	display: table-footer-group;
	font-weight: bold;
}
.divTableBody {
	display: table-row-group;
}
.dent01 {
	position:absolute;
	top:249px;
	left: 320px;
	width: 288px;
	text-align: center;
  object-fit: contain;
  font-family: NanumSquare_acR;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: #000;	
}
.dent02 {
	position:absolute; 
	top:164px; 
	left: 755px; 
	width: 182px; 
	text-align: center;
  object-fit: contain;
  font-family: NanumSquare_acR;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: #000;	
}
.dent03 {
	position:absolute; 
	top:164px; 
	left: 1125px; 
	width: 206px; 
	text-align: center;
  object-fit: contain;
  font-family: NanumSquare_acR;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: #000;	
}
.dent04 {
	position:absolute; 
	top:164px; 
	left: 1484px; 
	width: 252px; 
	text-align: center;
  object-fit: contain;
  font-family: NanumSquare_acR;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: #000;	
}
.dent05 {
	position:absolute; 
	top:656px; 
	left: 329px; 
	width: 288px; 
	text-align: center;
  object-fit: contain;
  font-family: NanumSquare_acR;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: #000;	
}
.rel1 {
	position:relative;
	justify-content : center;
	margin: 15px; 
}





            .userwrap {
                width: 100%;
                margin: 10px auto;
                position: relative;
            }
            .userimage img {
                width: 800px;
                vertical-align: middle;
            }
            .usertext {
                position: absolute;
                top: 50%;
                left: 60%;
                width: 100%;
                transform: translate( -50%, -50%);
                font-size: 20px;
                text-align: left;
            }
            .usertext img {
                position: absolute;
                left: 350px;
                top: -100px;
            }


            .userparent1 {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-gap: 10px;
                margin: 1px;
                padding: 10px;
                height: 350px;
                width: 800px;
            }
            .userwrap1 {
                float: left;
                width: 100%;
                margin: 10px auto;
                position: relative;
                top: 200px;
                left: 100px;
                display: inline-block;
            }
            .userimage1 img {
                width: 230px;
                vertical-align: middle;
            }
            .usertext1 {
                position: absolute;
                top: 174px;
                width: 230px;
                font-size: 18px;
                text-align: center;
                font-weight: bold;
                color: #0075ff;
            }
            .usertextp1 {
                font-size: 18px;
                font-weight: 550;
                color: #000000;
            }
            .usertextp2 {
                font-size: 28px;
                font-weight: 650;
                color: #000000;
            }
            .usertextp3 {
                font-size: 48px;
                font-weight: bold;
                color: #1226aa;
            }
.container {
  font-family: arial;
  font-size: 24px;
  margin: 25px;
  width: 100%;
  height: 100%;
}

p {
  /* 수평 중앙 정렬하기 */
  text-align: center;
}
