.Subtitle {
  display: flex;
  flex-direction: column;
  margin-top: 10vh;
  margin-left: 8vw;
  transition: 0.3s ease all;
}
.Subtitle h3 {
  font-family: 'AppleSDGothicNeoM';
  /* font-size: 2.6em; */
  font-size: 2.5rem;
}

.line {
  width: 100%;
  height: 3px;
  margin-top: -3vh;
  background: #747474;
}

.Subtitle h1 {
  font-size: 1rem;
}
@media (max-width: 1300px) {
  .Subtitle h3 {
    font-size: 2rem;
  }
}
@media (max-width: 100px) {
  .Subtitle h3 {
    font-size: 1.5rem;
  }
}

@media (max-width: 767px) {
  .Subtitle {
    margin: 10vh 0 0 0;
    align-items: center;
    z-index: 3;
  }
  .Subtitle h3 {
    font-family: 'AppleSDGothicNeoB';
    font-weight: normal;
    font-size: 5vmin;
    letter-spacing: -0.1vmin;
  }
  .line {
    width: 11.7vmin;
    height: 2px;
    margin-top: -2vmin;
    background: #747474;
    margin-bottom: -10vmin;
  }
}
