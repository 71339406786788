.bg {
  margin-top: 2%;
  display: flex;
  background-color: #f7f7f7;
  height: 80vh;
  margin-bottom: 31.2vh;
  /* background-color: chartreuse; */
}
.bg ul {
  margin-left: 3.4vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.smartphone {
  position: absolute;
  width: 53vw;
  right: 0%;
  margin-top: -12%;
  height: auto;
}
.bg li {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.3vh 3vw;
}
.bg li img {
  width: 10%;
  height: auto;
}
.bg .small {
  margin-top: -1vh;
  margin-right: -1%;
  width: 12%;
  height: auto;
}
.text {
  margin-left: 3.1vw;
  width: 33vw;
}
.text h3 {
  font-family: 'AppleSDGothicNeoL00';
  font-weight: 400;
  letter-spacing: 0.1rem;
  font-size: 2rem;
  width: 90%;
  /* background-color: chartreuse; */
}

@media (max-width: 1200px) {
  .text h3 {
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.6rem;
  }
  .bg {
    height: 70vh;
    margin-top: -4%;
    /* background-color: chartreuse; */
  }
  .bg .small {
    margin-top: 1%;
    margin-right: -1.8vw;
    width: 12%;
    height: auto;
  }
  .bg li {
    margin: 0.3vh 0vw;
  }
}
@media (max-width: 1050px) {
  .bg {
    height: 50vh;
    margin-top: -4%;
    height: 70vmin;
    /* background-color: chartreuse; */
  }
  .bg .small {
    margin-top: 1%;
    margin-left: 0.5vmin;
    width: 10.5%;
    margin-right: -1.7vmin;
  }
}

@media (max-width: 767px) {
  .bg {
    margin-top: -10vh;
    /* background-color: chartreuse; */
    height: 88vmin;
  }
  .bg ul {
    margin-left: -5vw;
  }
  .bg li {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0.3vh 3vw;
  }
  /* icons */
  .bg li img {
    width: 9vmin;
    height: auto;
  }
  .bg .small {
    margin-left: 0.3vmin;
    width: 10.5vmin;
  }
  /* ------------------------- */
  .text {
    margin-left: 0;
  }
  .cropping {
    position: relative;
    /* margin-left: -10vmin; */
    /* possible error */
    margin-top: -42vmin;
    width: 65vmin;
    /* possible error */
    overflow: hidden;
    height: 150vmin;
    /* background-color: chartreuse; */
  }
  .smartphone {
    overflow: hidden;
    position: absolute;
    width: 85vw;
    margin-top: 30vmin;
    transform: rotate(13.5deg);
    right: -40%;
    height: auto;
  }
  .text h3 {
    margin: 2vmin 0 5vmin 0;
    font-family: 'AppleSDGothicNeM00';
    font-weight: 400;
    color: #1e3257;
    letter-spacing: -0.05vmin;
    line-height: 4vmin;
    font-size: 3.5vmin;
    width: 45vmin;
  }
}
