.content {
  display: flex;
  flex-direction: column;
}
.ref img {
  position: absolute;
  margin-top: -96.5%;
  width: 100%;
  z-index: 1;
  opacity: 0.8;
}
