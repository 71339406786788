.Kickstarter {
  position: relative;
  margin-top: 24vh;
  width: 100%;
  height: 70vmin;
  /* background-color: chartreuse; */
  background-color: #f7f7f7;
  display: flex;
  flex-direction: row;
  transition: 0.3s ease all;
}
.text {
  align-self: center;
  margin-left: 8%;
  width: 84%;
  /* background-color: chartreuse; */
}
.text h1 {
  font-size: 4.25rem;
  color: #15233f;
  font-family: 'AppleSDGothicNeoB';
  font-weight: normal;
  letter-spacing: -0.1rem;
  margin-bottom: -0.2vw;
}
.text img {
  width: 44vmin;
}

.text h3 {
  margin-top: 6vw;
  font-size: 1.4rem;
  color: #747474;
  font-family: 'AppleSDGothicNeoM';
  font-weight: normal;
  letter-spacing: -0.01rem;
}
.videoWrap {
  margin-left: 45vw;
  position: absolute;
  /* padding-bottom: 80%; */
  top: 16%;
  height: 80%;
  width: 46.5%;
  overflow: hidden;
  /* background-color: chartreuse; */
}
.videoWrap iframe .videoWrap object,
.videoWrap embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 1300px) {
  .Kickstarter {
    height: 50vmin;
  }
  .text h1 {
    font-size: 3.1rem;
  }
  .text img {
    width: 30%;
  }
  .videoWrap {
    top: 10%;
    height: 100%;
  }
  .text h3 {
    font-size: 1.2rem;
  }
}
@media (max-width: 1000px) {
  .text h1 {
    font-size: 2.7rem;
  }
}

@media (max-width: 767px) {
  .Kickstarter {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    width: 80.4%;
    min-height: 80vw;
    height: 88vmin;
    margin-left: 9.8%;
    margin-bottom: 30%;
  }
  .videoWrap {
    top: 0%;
    margin: 0;
    width: 100%;
    height: 54vmin;
  }
  .text {
    margin: 0% 0% 0% 6%;
  }
  .text img {
    margin-top: 1vmin;
    width: 90.7%;
  }
  .text h1 {
    font-size: 8vw;
  }
  .text h3 {
    font-size: 4vw;
  }
}

@media (max-width: 350px) {
  .Kickstarter {
    /* height: 97vmin; */
  }
}
