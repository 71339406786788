.bg {
  margin-top: 14vh;
  margin-left: 8.3%;
  width: 91.7%;
  /* possible error */
  height: 75vmin;
  /* background-color: chartreuse; */
  background-color: #f7f7f7;
  display: flex;
  flex-direction: row;
  /* transition: 0.3s ease all; */
}

.videoWrap {
  justify-self: center;
  /* align-self: center; */
  position: absolute;
  /* padding-bottom: 80%; */
  margin-top: 10vh;
  margin-left: 17vw;
  height: 75vh;
  width: 55vw;
  overflow: hidden;
  /* background-color: chartreuse; */
}
.videoWrap iframe .videoWrap object,
.videoWrap embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 1600px) {
  .videoWrap {
    height: 80%;
    margin-top: 5vh;
  }
  .bg {
    height: 65vmin;
  }
}
@media (max-width: 1100px) {
  .videoWrap {
    height: 50%;
    margin-top: 10vh;
  }
  .bg {
    height: 55vmin;
  }
}
@media (max-width: 900px) {
  .videoWrap {
    height: 35%;
  }
  .bg {
    height: 50vmin;
  }
}

@media (max-width: 767px) {
  .bg {
    margin: 18vmin 0 0vmin 0;
    width: 100%;
    height: 47.7vmin;
    /* possible error */
    background-color: #1e3257;
  }
  .videoWrap {
    position: absolute;
    /* padding-bottom: 80%; */
    margin: 0vh;
    left: 50%;
    transform: translate(-50%, 0%);
    height: 68vmin;
    width: 85vmin;
    overflow: hidden;
  }
}
