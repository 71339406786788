.content {
  display: flex;
  flex-direction: column;
}
.ref img {
  position: absolute;
  margin-top: -96.5%;
  width: 100%;
  z-index: 1;
  opacity: 0.8;
}

.parent{
  position:relative;
}

@media only screen and (min-width: 1000px) {
  .video {
    width: 100%;
  }
}

@media only screen and (min-width: 1400px) {
  .video {
    position: relative;
    width: 1400px;
    top:50%;
    left:50%;
    transform: translate(-50%,0%);
  }
}


