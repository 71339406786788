.content {
  margin-top: 10vh;
  margin-left: 8vw;
}
.content div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.first img {
  width: 57.5vw;
  height: auto;
  margin-left: -3.5vw;
}
.second img {
  width: 64.5vw;
  height: auto;
  margin-left: 0vw;
  margin-top: -15.5vh;
}
.content h1 {
  font-family: 'AppleSDGothicNeoB00';
  font-weight: normal;
  font-size: 2.3rem;
  letter-spacing: -0.02rem;
  line-height: 2.8rem;
  text-align: center;
}
.first h1 {
  margin-top: -2vh;
  margin-left: 3.5vw;
  width: 30%;
  /* background-color: chartreuse; */
}
.second h1 {
  width: 50%;
  /* background-color: chartreuse; */
}

@media (max-width: 1300px) {
  .content h1 {
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 1.9rem;
    letter-spacing: 0.05rem;
    text-align: center;
  }
}
@media (max-width: 1200px) {
  .content h1 {
    font-size: 1.5rem;
    line-height: 1.6rem;
    text-align: center;
    letter-spacing: -0.04rem;
  }
}
@media (max-width: 767px) {
  .content {
    margin: 18vmin 0;
  }
  .content div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .content .second {
    flex-direction: column-reverse;
  }
  .content h1 {
    font-size: 1.2rem;
    font-weight: 800;
    line-height: 1.3rem;
    letter-spacing: -0.01rem;
  }
  .first img {
    width: 60vw;
    height: auto;
    margin-left: -3.5vw;
  }
  .second img {
    width: 88vw;
    align-self: flex-end;
    height: auto;
    margin: 0;
  }
  .first h1 {
    margin: -4vmin 0 15vmin 0;
    text-align: center;
    width: 55vmin;
    /* background-color: chartreuse; */
  }
  .second h1 {
    margin: -12vmin 0 15vmin 0;
    text-align: center;
    width: 55vmin;
    /* background-color: chartreuse; */
  }
}
